import * as React from "react";

import type CSS from "csstype";
import { graphql } from "gatsby";

import {
  type ISiteLinkProps,
  LinkList,
  ResponsiveCard,
  SiteLink,
} from "@parataxic/shared-ui";

import { ListView } from "../components/ListView";
import { PopoverSiteLink } from "../components/Popover";

interface IViewItemProps<P> {
  item: P;
}

const ViewItem: React.FC<IViewItemProps<GatsbyTypes.PdfMediaItemFragment>> = ({
  item,
}) => (
  <ResponsiveCard
    style={{
      maxWidth: "1000px",
      margin: "0 auto 1rem",
      marginBottom: "1rem",
      padding: ".75rem",
      display: "block",
      width: "100%",
    }}
    role="listitem"
  >
    <div>
      <SiteLink
        href={item.slug}
        style={{
          color: "var(--color-gray-800)",
          fontWeight: "var(--fontWeights-bold)",
          width: "100%",
        }}
      >
        {item.title} {item.createdAt && `(${item.createdAt})`}
      </SiteLink>
    </div>
    {item.description && <div>{item.description}</div>}
    <div style={{ display: "grid", gridColumn: "5" }}>
      <div style={{ alignSelf: "center" }}>
        <LinkList links={item.mentionedTheories} Component={PopoverSiteLink} />
      </div>
      <div style={{ alignSelf: "center", textAlign: "center" }}>
        <LinkList links={item.creators} Component={PopoverSiteLink} />
      </div>
      <div style={{ textAlign: "right" }}>
        <DownloadLinks
          pdfLinks={item.pdfLinks}
          linkProps={{
            style: {
              marginLeft: "var(--space-4)",
            },
          }}
          LinkComponent={({ children }) => (
            <span
              style={{
                backgroundColor: "var(--colors-blue-700)",
                color: "white",
                padding: "var(--space-1)",
              }}
            >
              {children}
            </span>
          )}
        />
      </div>
    </div>
  </ResponsiveCard>
);

interface IDownloadLinksProps {
  pdfLinks: GatsbyTypes.MdxPdf["pdfLinks"];
  color?: CSS.Property.Color;
  linkProps?: ISiteLinkProps;
  LinkComponent?: React.ComponentType;
}

export const DownloadLinks: React.FC<IDownloadLinksProps> = ({
  pdfLinks,
  color,
  linkProps = {},
  LinkComponent = React.Component,
}) =>
  pdfLinks && pdfLinks.length > 0 ? (
    <>
      <span style={{ paddingRight: "1rem" }}>PDF:</span>
      {pdfLinks.map((link, idx: number) => [
        <SiteLink
          href={link}
          rel="noopener noreferrer"
          target="_blank"
          key={idx}
          style={{
            color,
            textTransform: "uppercase",
            fontWeight: "600",
            fontSize: ".8rem",
            letterSpacing: 1,
            textShadow: "var(--colors-gray-880)",
            backgroundColor: "var(--colors-blue-700)",
            padding: "var(--space-1)",
          }}
          {...linkProps}
        >
          <LinkComponent>{`#${idx + 1}`}</LinkComponent>
        </SiteLink>,
      ])}
    </>
  ) : null;

const View = ({
  data,
}: {
  data: GatsbyTypes.PdfMediaListQuery;
}): React.ReactNode => {
  return (
    <ListView<GatsbyTypes.PdfMediaItemFragment>
      edges={data.allMdxPdf.edges}
      ListItem={ViewItem}
      seoProps={{
        title: "Read psych papers, download PDFs directly",
      }}
    />
  );
};

export const mediaQuery = graphql`
  fragment PdfMediaItem on MdxPdf {
    id
    title
    description
    pdfLinks
    slug
    createdAt(formatString: "YYYY")
    mentionedTheories {
      id
      title
      slug
    }
    creators {
      id
      handle
      slug
    }
  }
  query PdfMediaList {
    allMdxPdf {
      edges {
        node {
          ...PdfMediaItem
        }
      }
    }
  }
`;

export default View;
